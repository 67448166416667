import React, { useRef } from "react";
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import "./Verifier.css";
import { Link, useLocation } from "react-router-dom";
import { AWS_BASE_URL } from "../../config/config";
import { useReactToPrint } from "react-to-print";

class ComponentToPrint extends React.Component {
  render() {
    const application = this.props.application;
    return (
      <div>
        <Container>
          <div className="verifier-wrapper">
            <h2 className="verify-head">
              Application Form : {application?.applicationId}
            </h2>
            <Row>
              <Col md="4">
                <p className="input-title">كلية</p>
                <p className="input-response">{application?.course}</p>
              </Col>
              <Col md="4">
                <p className="input-title">الشعبة /الفرقة</p>
                <p className="input-response">{application?.subCourse}</p>
              </Col>
              <Col md="4">
                <p className="input-title">Photo</p>

                <div className="input-image">
                  <img src={AWS_BASE_URL + application?.photo?.file} />
                </div>
              </Col>
              <Col md="4">
                <p className="input-title">Name</p>
                <p className="input-response">{application?.name}</p>
              </Col>
              <Col md="4">
                <p className="input-title">Place</p>
                <p className="input-response">{application?.place}</p>
              </Col>
              <Col md="4">
                <p className="input-title">House Name/No</p>
                <p className="input-response">{application?.houseName}</p>
              </Col>
              <Col md="4">
                <p className="input-title">Date of Birth</p>
                <p className="input-response">
                  {application?.dob?.day +
                    " - " +
                    application?.dob?.month +
                    " - " +
                    application?.dob?.year}
                </p>
              </Col>
              <Col md="4">
                <p className="input-title">Nationalality</p>
                <p className="input-response">{application?.nationalality}</p>
              </Col>
              <Col md="4">
                <p className="input-title">State</p>
                <p className="input-response">{application?.state}</p>
              </Col>
              <Col md="4">
                <p className="input-title">District</p>
                <p className="input-response">{application?.district}</p>
              </Col>
              <Col md="4">
                <p className="input-title">Post Office</p>
                <p className="input-response">{application?.postOffice}</p>
              </Col>
              <Col md="4">
                <p className="input-title">Pincode</p>
                <p className="input-response">{application?.pinCode}</p>
              </Col>
              <Col md="4">
                <p className="input-title">Mobile Number</p>
                <p className="input-response">{application?.mobileNumber}</p>
              </Col>
              <Col md="4">
                <p className="input-title">Landline Number</p>
                <p className="input-response">{application?.landlineNumber}</p>
              </Col>
              <Col md="4">
                <p className="input-title">Adhaar Number</p>
                <p className="input-response">{application?.AdhaarNumber}</p>
              </Col>
              <Col md="4">
                <p className="input-title">Email Address</p>
                <p className="">{application?.email}</p>
              </Col>
              <Col md="4">
                <p className="input-title">Blood Group</p>
                <p className="input-response">{application?.bloodGroup}</p>
              </Col>
            </Row>
            <Row>
              <h4 className="verify-head">FAMILY DETAILS</h4>
              <Col md="4">
                <p className="input-title">Name of Father</p>
                <p className="input-response">{application?.fatherName}</p>
              </Col>
              <Col md="4">
                <p className="input-title">Occupation of Father</p>
                <p className="input-response">
                  {application?.fatherOccupation}
                </p>
              </Col>
              <Col md="4">
                <p className="input-title">Name of Mother</p>
                <p className="input-response">{application?.motherName}</p>
              </Col>
              <Col md="4">
                <p className="input-title">Current Guardian</p>
                <p className="input-response">{application?.currentGuardian}</p>
              </Col>
              <Col md="4">
                <p className="input-title">Contact Number</p>
                <p className="input-response">{application?.contactNumber}</p>
              </Col>
            </Row>
            <Row>
              <h4 className="verify-head">School/University Education</h4>

              <Col md="4">
                <p className="input-title">
                  Name & Place of Previous institute/التعليم الإسلامي
                </p>
                <p className="input-response">{application?.prevInstitute}</p>
              </Col>
            </Row>
            <Row>
              <h4 className="verify-head">ORGANISATIONAL EXPERIENCE</h4>

              <Col md="4">
                <p className="input-title">Designation</p>
                <p className="input-response">{application?.designation}</p>
              </Col>
              <Col md="4">
                <p className="input-title">SSF / SYS Unit</p>
                <p className="input-response">{application?.SYSUnit}</p>
              </Col>
              <Col md="4">
                <p className="input-title">Circle / Sector </p>
                <p className="input-response">{application?.sector}</p>
              </Col>
              <Col md="4">
                <p className="input-title">Division / Zone</p>
                <p className="input-response">{application?.zone}</p>
              </Col>
            </Row>
            <Row>
              <h4 className="verify-head">VACCINE DETAILS</h4>
              <Col md="4">
                <p className="input-title">Vaccinated</p>
                <p className="input-response">{application?.vaccinated}</p>
              </Col>
              <Col md="4">
                <p className="input-title">Type of Vaccine</p>
                <p className="input-response">
                  {application?.vaccineType ? application?.vaccineType : " - "}
                </p>
              </Col>
              <Col md="4">
                <p className="input-title">How many doses?</p>
                <p className="input-response">
                  {application?.vaccineDose ? application?.vaccineDose : " - "}
                </p>
              </Col>
              <Col md="4">
                <p className="input-title">
                  Have you ever been covid positive?
                </p>
                <p className="input-response">
                  {application?.covidPositive
                    ? application?.covidPositive
                    : " - "}
                </p>
              </Col>
            </Row>
            <Row>
              <h4 className="verify-head">EDUCATIONAL DETAILS:</h4>
              <h4 className="verify-head">
                PLEASE WRITE THE NAMES OF THE BOOKS YOU STUDIED IN EACH OF THE
                FOLLOWING ARTS (اكتب أسماء الكتب التي درستها في كل فن من الفنون
                الآتية)
              </h4>
              <Col md="12">
                <p className="input-title">علم التوحيد</p>
                <p className="input-response">{application?.theology}</p>
              </Col>
              <Col md="12">
                <p className="input-title">علم التفسير</p>
                <p className="input-response">{application?.tafsir}</p>
              </Col>
              <Col md="12">
                <p className="input-title">علوم القرآن</p>
                <p className="input-response">{application?.quran}</p>
              </Col>
              <Col md="12">
                <p className="input-title">علم الحديث</p>
                <p className="input-response">{application?.hadith}</p>
              </Col>
              <Col md="12">
                <p className="input-title">مصطلح الحديث</p>
                <p className="input-response">
                  {application?.hadithTerminology}
                </p>
              </Col>
              <Col md="12">
                <p className="input-title">أصول الفقه</p>
                <p className="input-response">
                  {application?.jurisprudencePrinciples}
                </p>
              </Col>
              <Col md="12">
                <p className="input-title">علم الفقه</p>
                <p className="input-response">{application?.jurisprudence}</p>
              </Col>
              <Col md="12">
                <p className="input-title">التصوف</p>
                <p className="input-response">{application?.sufism}</p>
              </Col>
              <Col md="12">
                <p className="input-title">التاريخ</p>
                <p className="input-response">{application?.history}</p>
              </Col>
              <Col md="12">
                <p className="input-title">النحو</p>
                <p className="input-response">{application?.grammar}</p>
              </Col>
              <Col md="12">
                <p className="input-title">الصرف</p>
                <p className="input-response">{application?.swarf}</p>
              </Col>
              <Col md="12">
                <p className="input-title">البلاغة</p>
                <p className="input-response">{application?.balaga}</p>
              </Col>
              <Col md="12">
                <p className="input-title">العروض والقوافي</p>
                <p className="input-response">{application?.prosody}</p>
              </Col>
              <Col md="12">
                <p className="input-title">المنطق</p>
                <p className="input-response">{application?.logics}</p>
              </Col>
              <Col md="12">
                <p className="input-title">الحساب</p>
                <p className="input-response">{application?.arithmetics}</p>
              </Col>
              <Col md="12">
                <p className="input-title">الهيئة</p>
                <p className="input-response">{application?.astronomy}</p>
              </Col>
              <Col md="12">
                <p className="input-title">الميقات</p>
                <p className="input-response">{application?.meeqat}</p>
              </Col>
            </Row>
            <Row>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Teacher / mentor</th>
                    <th>Number</th>
                  </tr>
                </thead>
                <tbody>
                  {application?.prevTeachers?.map((person, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{person?.name}</td>
                      <td>{person?.mobile}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Row>
            <small>
              ** Your payment done successfully, Payment ID :{" "}
              {application?.razorpay?.razorpay_order_id}{" "}
            </small>
          </div>
        </Container>
      </div>
    );
  }
}

function Verifier() {
  const location = useLocation();
  const { state } = location;
  const { application } = state;

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div>
      <ComponentToPrint ref={componentRef} application={application} />
      <div className="print-button-wrapper">
        <Button className="print-button" onClick={() => handlePrint()}>
          Print
        </Button>
      </div>
    </div>
  );
}

export default Verifier;

import axios from "axios";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { baseURL } from "../../config/config";
import "./Successful.css";

function Successful() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const submitHandler = async () => {
    try {
      const res = await axios.get(
        baseURL + `/application?applicationId=${state.applicationId}`
      );
      //   console.log(res.data);
      if (res.data.success) {
        navigate("/verifier", {
          state: {
            application: res.data.application,
          },
        });
      }
    } catch (error) {
      console.log(error.response.data);
    }
  };

  return (
    <div>
      <div className="success-wrapper">
        <span>Dear {state.name},</span>
        <p className="success-message">
          Your Application{" "}
          <span className="success-id">
            <b>{state.applicationId}</b>
          </span>{" "}
          form has been submitted successfully
        </p>
        <div className="success-button-wrap">
          <Link to="/">Back</Link>
          <a className="primary-btn" onClick={submitHandler}>
            View
          </a>
        </div>
      </div>
    </div>
  );
}

export default Successful;

import React from 'react';
import { Container } from 'react-bootstrap';
import admission from '../../assets/admission-2019.jpg';
import './Query.css'


function Query() {
    return (
        <div>
            <Container>
                <div className='query-help-wrap'>
                    <h4>Help</h4>
                </div>
                <div className='query-img-wrap'>
                <img src={admission} className=''/>


                </div>
            </Container>
        </div>
    )
}

export default Query

import React from "react";
import "./Hallticket.css";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function Hallticket() {
  const navigate = useNavigate();

  return (
    <div>
      <div className="print-application">
        <Form.Group className="form-group" controlId="">
          <Form.Label>Application Number</Form.Label>
          <Form.Control type="text" placeholder="" />
        </Form.Group>
        <div className="print-button-wrapper">
          <Button
            className="print-button"
            onClick={() => navigate("/print-hall-ticket")}
          >
            Generate
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Hallticket;

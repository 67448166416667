import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Register from "./components/form/Register";
import Header from "./components/Header";
import Home from "./components/view/Home";
import Query from "./components/view/Query";
import Guidelines from "./components/view/Guidelines";
import Verifier from "./components/review/Verifier";
import PrintApplication from "./components/application/PrintApplication";
import Successful from "./components/form/Successful";
import Footer from "./components/Footer";
import Hallticket from "./components/hallticket/Hallticket";
import GenerateHallTicket from "./components/generateHallticket/GenerateHallTicket";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/query" element={<Query />} />
        <Route path="/guidelines" element={<Guidelines />} />
        <Route path="/verifier" element={<Verifier />} />
        <Route path="/my-application" element={<PrintApplication />} />
        <Route path="/hallticket" element={<Hallticket />} />
        <Route path="/print-hall-ticket" element={<GenerateHallTicket />} />

        <Route path="/successfull" element={<Successful />} />
        <Route path="/" element={<Register />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;

import React from "react";
import { Container } from "react-bootstrap";

import "./Register.css";

function Register() {
  return (
    <div>
      <Container>
        <h3 style={{ color: "red" }}>
          Markaz Islamic Courses 2022 - Registration Closed.
        </h3>
        <h5>
          Contact for further information: +91 9072 500 443, +91 9072 500 423,
          +91 9495 137 947, +91 9072 712 800
        </h5>
      </Container>
    </div>
  );
}

export default Register;

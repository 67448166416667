import React from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Header.css";
import logo from "../assets/markaz-logonew.png";
import { BiHelpCircle, BiDirections } from "react-icons/bi";
import { AiOutlineHome } from "react-icons/ai";
import { BsFileEarmarkCheck } from "react-icons/bs";

function Header() {
  return (
    <div>
      <div className="top-bar">
        <Container>
          <div>
            <Link to="/">
              <img src={logo} />
            </Link>
          </div>
        </Container>
      </div>
      <Navbar expand="lg">
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Link className="nav-menu-items" to="/home">
                <AiOutlineHome />
                <span>Home</span>{" "}
              </Link>

              <Link className="nav-menu-items" to="/guidelines">
                <BiDirections />
                <span>Guidelines</span>
              </Link>

              <Link className="nav-menu-items" to="/query">
                <BiHelpCircle />
                <span>Query</span>
              </Link>

              <Link className="nav-menu-items" to="/my-application">
                <BsFileEarmarkCheck />
                <span>My Application</span>
              </Link>
              <Link className="nav-menu-items" to="hallticket">
                <BsFileEarmarkCheck />
                <span>Hallticket</span>
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Header;

import React, { useEffect, useState } from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import ReactDOM from "react-dom";
import Pdf from "react-to-pdf";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import "./style.css";
import "./GenerateHallTicket.css";
import hallHead from "../../assets/hallticket-logo.png";
import { BsArrowRight } from "react-icons/bs";

const ref = React.createRef();

const GenerateHallTicket = () => {
  return (
    <div className="App">
      <BrowserView>
        <div
          style={{ width: 792, height: 1100, backgroundColor: "#fff" }}
          ref={ref}
        >
          <div className="hallticket_header">
            <div className="hallticket_header_img">
              <img src={hallHead} />
            </div>
          </div>
          <div className="details_wrapper">
            <h3>Interview Card</h3>
            <div>
              <div className="student_info_appn_id">
                Application Number : <span>MSC191919</span>
              </div>
              <div className="student_info">
                <div className="student_info_details">
                  <div className="student_info_details_item">
                    <div className="student_info_details_item_left">
                      Full Name:
                    </div>
                    <div className="student_info_details_item_right">
                      Muhammed Mubashir K Kuttikattur
                    </div>
                  </div>
                  <div className="student_info_details_item">
                    <div className="student_info_details_item_left">
                      Date of Birth:
                    </div>
                    <div className="student_info_details_item_right">
                      Pranav mohan k
                    </div>
                  </div>
                  <div className="student_info_details_item">
                    <div className="student_info_details_item_left">
                      Address:
                    </div>
                    <div className="student_info_details_item_right">
                      Pranav mohan k
                    </div>
                  </div>
                  <div className="student_info_details_item">
                    <div className="student_info_details_item_left">
                      Last Studied:
                    </div>
                    <div className="student_info_details_item_right">
                      Pranav mohan k
                    </div>
                  </div>
                  <div className="student_info_details_item">
                    <div className="student_info_details_item_left">
                      Applying for:
                    </div>
                    <div className="student_info_details_item_right">
                      Pranav mohan k
                    </div>
                  </div>
                </div>
                <div className="student_info_img">
                  <div className="student_qr_code">
                    <div>
                      <img src="https://markazapplication.s3.ap-south-1.amazonaws.com/application/1642963948454IMG-20220124-WA0001.jpg" />
                    </div>
                  </div>
                  <div className="student_img">
                    <div>
                      <img src="https://markazapplication.s3.ap-south-1.amazonaws.com/application/1642963948454IMG-20220124-WA0001.jpg" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="student_tick_table">
                  <div className="student_tick_table_item1">
                    Documents <BsArrowRight />
                  </div>
                  <div className="student_tick_table_item2">
                    Organisation Letter
                  </div>
                  <div className="student_tick_table_item3">
                    Reccomendation Letter
                  </div>
                  <div className="student_tick_table_item4">ID Proof</div>
                  <div className="student_tick_table_item5">
                    Submitted Status <BsArrowRight />
                  </div>
                  <div className="student_tick_table_item6">Submitted</div>
                  <div className="student_tick_table_item7">Submitted</div>

                  <div className="student_tick_table_item8">Submitted</div>
                </div>
              </div>
              <div className="student_extra">
                fficia aliqua sint ex aliqua labore officia irure aliquip.
              </div>
              <div className="instructon_table">
                <h4 className="instructon_table_head">
                  പരീക്ഷാര്‍ത്ഥികള്‍ക്കുള്ള നിര്‍ദ്ദേശങ്ങള്‍ Instructions to the
                  candidates
                </h4>
                <ol className="instructon_table_list">
                  <li className="">
                    2022 ഫെബ്രുവരി 26 ശനി രാവിലെ 08 മണിക്ക് മുമ്പ് ഇന്റര്‍വ്യൂ
                    കാര്‍ഡിന്റെ 2 പ്രിന്റ്ഡ് കോപ്പി റജിസ്‌ട്രേഷന്‍ കൗണ്ടറില്‍
                    കാണിച്ച് സീല്‍ വാങ്ങേണ്ടതാണ്. (ഒന്ന് കൗണ്ടറില്‍
                    ഏല്‍പ്പിക്കേണ്ടതും മറ്റൊന്ന് അപേക്ഷകന്‍ അഡ്മിഷന്‍
                    പ്രക്രിയകള്‍ കഴിയുന്നത് വരെ സൂക്ഷിക്കേണ്ടതും ആവശ്യാനുസരണം
                    കാണിക്കേണ്ടതുമാണ്). <br />
                    Candidates are advised to take 2 copies of the printed hall
                    ticket and get them stamped from the registration counter.
                    (One for office use and the other to keep with the candidate
                    for admission purpose).
                  </li>
                  <li>
                    കോഴ്സുകളെ സംബന്ധിച്ചുള്ള ഓറിയന്റേഷന്‍ ക്ലാസ് അന്നേദിവസം
                    രാവിലെ 09.15ന് മര്‍കസ് ഓഡിറ്റോറിയത്തില്‍ വെച്ച്
                    ഉണ്ടായിരിക്കും. അതില്‍ നിര്‍ബന്ധമായും പങ്കെടുക്കേണ്ടതാണ്.
                    <br />
                    After registration candidate have to participate in the
                    orientation programme about the courses at Markaz Auditorium
                    at 09.15 am.{" "}
                  </li>
                  <li>
                    പരീക്ഷയുടെ സുഗമമായ നടത്തിപ്പിന് സ്ഥാപനമേധാവികളും
                    ഇന്‍വിജിലേറ്റര്‍മാരും നിര്‍ദ്ദേശിക്കുന്ന കാര്യങ്ങള്‍
                    പൂര്‍ണ്ണമായും അനുസരിക്കേണ്ടതാണ്. <br />
                    Instructions given by the Head of the institution and
                    Invigilators are to be strictly followed.{" "}
                  </li>
                  <li>
                    പരീക്ഷ ആരംഭിച്ചതിന് ശേഷം പരീക്ഷ ഹാളിലേക്ക്
                    പ്രവേശിപ്പിക്കുന്നതല്ല.
                    <br /> The candidates are not allowed to enter the
                    examination hall after the exam started.
                  </li>
                  <li>
                    കോവിഡുമായി ബന്ധപ്പെട്ട് സര്‍ക്കാര്‍ നിര്‍ദ്ദേശിച്ച
                    മാനദണ്ഡങ്ങള്‍ പൂര്‍ണ്ണമായും പാലിക്കേണ്ടതാണ്. (മാസ്‌ക്
                    ധരിക്കുക, കൈകള്‍ സാനിറ്റൈസ് ചെയ്യുക, സാമൂഹിക അകലം പാലിക്കുക)
                    <br />
                    The candidates are strictly advised to follow the Govt.of
                    Kerala instructions about COVID - 19 (Wear mask, sanitize
                    hands, Keep social distance).
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div style={{ width: 792, textAlign: "center", margin: "20px 0" }}>
          <Pdf targetRef={ref} filename="code-example.pdf">
            {({ toPdf }) => (
              <button className="download_pdf" onClick={toPdf}>
                Generate Pdf
              </button>
            )}
          </Pdf>
        </div>
      </BrowserView>
      <MobileView>
        <h1>Use desktop/PC to download hallticket</h1>
      </MobileView>
    </div>
  );
};

export default GenerateHallTicket;

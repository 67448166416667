import React, { useState } from "react";
import "./PrintApplication.css";
import { Button, Form } from "react-bootstrap";
import axios from "axios";
import { baseURL } from "../../config/config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

function PrintApplication() {
  const navigate = useNavigate();
  const [applicationId, setApplicationId] = useState("");

  const notify = (msg) => toast(msg);

  const submitHandler = async () => {
    try {
      const res = await axios.get(
        baseURL + `/application?applicationId=${applicationId}`
      );
      //   console.log(res.data);
      if (res.data.success) {
        navigate("/verifier", {
          state: {
            application: res.data.application,
          },
        });
      } else {
        notify("Application not found");
      }
    } catch (error) {
      notify(error.response.data.message);
      console.log(error.response.data);
    }
  };

  return (
    <div>
      <div className="print-application">
        <Form.Group className="form-group" controlId="">
          <Form.Label>Application Number</Form.Label>
          <Form.Control
            type="text"
            placeholder=""
            value={applicationId}
            onChange={(e) => setApplicationId(e.target.value.toUpperCase())}
          />
        </Form.Group>
        <div className="print-button-wrapper">
          <Button className="print-button" onClick={() => submitHandler()}>
            Submit
          </Button>
        </div>
      </div>
      <div></div>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default PrintApplication;

import React from 'react'
import { Container } from 'react-bootstrap';
import './Guidelines.css'

function Guidelines() {
    return (
        <div>
            <Container>
                <div className='guidelines-wrapper'>
                    <h4>Guidelines for Application</h4>
                    <p>Guidelines are updating...</p>
                    <a href='' className='guidelines-button'>Download Kulliyyath Prospectus PDF</a>
                </div>
            </Container>
            
        </div>
    )
}

export default Guidelines

import React from 'react'
import { Container } from 'react-bootstrap';
import './Footer.css'

function Footer() {
    return (
        <div className='footer'>
            <Container>
                <div className='footer-wrapper'>
                    <p className='footer-left'>© Markaz IT Dept. </p>
                    <p className='footer-right'> Updated on 18/01/2022 </p>
                </div>
            </Container>
            
        </div>
    )
}

export default Footer

import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import './Home.css';
import img1 from '../../assets/markaz-arts.png';

function Home() {
    return (
        <div className='home-section'>
            <Container>
                <div className='home-wrapper'>
                <Row>
                    <div className='alert-success'>
                        <p>Jamia Markaz : Half Yearly Examination Result Published! <a href='' className='alert-link'>Check Now</a> </p>
                    </div>
                    <h2>Welcome to Markaz Online Admission Portal</h2>
                    <p className='home-content'>Markaz is one of the best collegiate Universities in South India. It has mainly eight types of institutes and two types of public schools, serving various academic and professional objectives, and ranging from KG level to PG. The institutes and schools under Markaz have separate management system and governing body, yet operating under the academic directions delivered from the central body of Markaz.</p>
                </Row>
                <div>
                    <Row>
                        <h4 className='box-head'>INSTITUTIONS</h4>
                        <Col md="4">
                            <div className='box'>
                                <div>
                                    <img src={img1} className='box-img'/>
                                </div>
                                <div className='box-content'>
                                    <h3>Markaz College of Arts & Science Admission (Management Qouta)</h3>
                                    <p>Instructions and application form for management qouta admission to Markaz College of Arts & Science</p>
                                    <div className='button-box-wrap'>
                                        <a href='' className='box-button'>Download Application Form (UG)</a>
                                        <a href='' className='box-button'>Download Application Form (UG)</a>


                                    </div>
                                </div>
                                
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4">
                        <div className='card'>
                                <div>
                                    <img src={img1} className='card-img'/>
                                </div>
                                <div className='card-content'>
                                    <h3>Markaz College of Arts & Science Admission (Management Qouta)</h3>
                                    <p>Instructions and application form for management qouta admission to Markaz College of Arts & Science</p>
                                    <div className='button-card-wrap'>
                                        <a href='' className='card-button'>Apply Now</a>


                                    </div>
                                </div>
                                
                            </div>

                        
                        </Col>

                    </Row>
                </div>
                </div>
            </Container>
        </div>
    )
}

export default Home
